import './footer.scss';

function Footer() {
  return (
    <div className='footer_styling'>
      <div className='footer_top'>
        <div style={{ marginRight: '14px' }}>
          <a
            href='https://myome.com/hipaa-notice-of-privacy-practices/'
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: '#003595', marginRight: '2px' }}
          >
            HIPAA Notice of Privacy Practices
          </a>
          |
          <a
            href='https://myome.com/terms-of-service/'
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: '#003595', margin: '0 2px' }}
          >
            Terms of Service
          </a>
          |
          <a
            href='https://myome.com/privacy-notice/'
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: '#003595', marginLeft: '2px' }}
          >
            Privacy Notice
          </a>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', color: '#ACAEB2' }}>
          <div>© 2023 MyOme.com</div>
          <div>MyOme is not responsible for the content or accuracy of third-party websites</div>
        </div>
      </div>
      <div className='footer_bottom'>
        MyOme&apos;s tests are developed, and the performance characteristics determined, by MyOme, Inc., a clinical
        laboratory certified under the Clinical Laboratory Improvement Amendments of 1988 (CLIA) to perform high
        complexity clinical laboratory testing. MyOme&apos;s tests have not been cleared or approved by the U.S. Food
        and Drug Administration (FDA). MyOme Inc. is College of American Pathologists (CAP) accredited and CLIA
        certified.
      </div>
    </div>
  );
}

export default Footer;
